import classNames from "classnames";
import { SystemOneLogo } from "components/Atoms/brand/SystemOneLogo";
import React from "react";

interface AuthPanelProps extends React.PropsWithChildren {
  additionalFooterContent?: React.ReactNode
  mobile?: boolean
}

const AuthPanel = (props: AuthPanelProps) => {
  return <div className={classNames("s1_main-panel small auth-panel", { mobile: props.mobile })} >
    <div style={{ alignSelf: "center", transform: "scale(1.1)" }}>
      <a href={site.promoSiteUrl} target="_blank" rel="noreferrer">
        <SystemOneLogo />
      </a>
    </div>
    <div className="s1_main-panel-content">
      {props.children}
    </div>
    <div className="auth-panel-footer">
      {props.additionalFooterContent}
    </div>
  </div>;
};

export default AuthPanel;