import { KeyboardNavigationOptions } from "./model"

interface CustomKeyboardNavigationBehaviour {
  condition: (element: HTMLElement) => boolean
  options: KeyboardNavigationOptions
}

const reactSelectDropdownBehaviour: CustomKeyboardNavigationBehaviour = {
  condition: (element) => {
    const id = element.getAttribute("id") || "";
    return /react-select-[0-9]*-input/.test(id);
  },
  options: {
    navigationGroup: "",
    navigationGroupDirection: "horizontal",
    navigationGroupLeaveOnTab: true,
    focusShiftOnEnter: false,
    spaceEnabled: false,
    navigationDisabled: true
  }
}

const textAreaBehaviour: CustomKeyboardNavigationBehaviour = {
  condition: (element) => element.tagName === "TEXTAREA",
  options: {
    navigationGroup: "",
    navigationGroupDirection: "horizontal",
    navigationGroupLeaveOnTab: true,
    focusShiftOnEnter: true,
    spaceEnabled: false,
    navigationDisabled: true
  }
}

export const customRules = [reactSelectDropdownBehaviour, textAreaBehaviour];