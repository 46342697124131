import { PhosphorMoon, PhosphorSun } from "components/Atoms/PhosphorIcon";
import { PhosphorCircleHalfButton } from "components/Atoms/PhosphorIconButton";
import { getTheme, setTheme } from "components/theme/theme";
import { HorizontalKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";
import React, { useState } from "react";
import Popup from "reactjs-popup";

export const ThemeSelectMenu = () => {
  const [theme, setThemeValue] = useState<server.dto.Themes>(getTheme());

  const changeTheme = (theme: server.dto.Themes) => {
    setThemeValue(theme);
    setTheme(theme);
  }

  return <>
    <Popup
      className="top-bar-popup"
      trigger={<PhosphorCircleHalfButton
        {...HorizontalKeyboardNavigationGroup("topBar")}
        layout="in-circle"
        iconSize="large" />}
      position="bottom right"
      closeOnDocumentClick
      onOpen={() => { }}
      onClose={() => { }}
      contentStyle={{ padding: "0px", border: "none" }}
      arrow={false}
    >
      <div className="popup-menu-wrapper">
        <span tabIndex={0} role="button" onClick={() => changeTheme("light")} className="popup-menu-item">
          <div className="grow1">Lumen</div>
          <div className="menu-icon">{theme === "light" && <PhosphorSun />}</div>
        </span>
        <span tabIndex={0} role="button" onClick={() => changeTheme("dark")} className="popup-menu-item">
          <div className="grow1">Eclipse</div>
          <div className="menu-icon">{theme === "dark" && <PhosphorMoon />}</div>
        </span>
      </div>
    </Popup>
  </>
}