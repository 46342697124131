import Sublabel from "components/Atoms/Sublabel";
import React from "react";
import { type Props, components } from "react-select";
import { Loader } from "semantic-ui-react";
import { focusOnMount } from "utilities/reactUtils";
import { useDebounce } from "utilities/usehooks";
import { type CommonReactSelectWrapperProps } from "./models";
import { PhosphorMagnifyingGlass, PhosphorCaretDown } from "components/Atoms/PhosphorIcon";

// We don't care about TOption here
// If we make this generic, the typings will get a lot more complicated to support multi select dropdowns
type WrapperProps = CommonReactSelectWrapperProps<unknown, false, any>;

export interface UseSearchQuery {
  searchQuery: string
  setSearchQuery: (value: string) => void
  isDebouncing: boolean
  nonDebouncedSearchQuery: string
}
export const useSearchQuery = (initialSearchQuery = ""): UseSearchQuery => {
  const [searchQuery, setSearchQuery] = React.useState(initialSearchQuery);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  return {
    searchQuery: debouncedSearchQuery,
    setSearchQuery,
    isDebouncing: searchQuery !== debouncedSearchQuery,
    nonDebouncedSearchQuery: searchQuery
  };
};

export type ReactSelectDropdownVariant = "default" | "borderless";

export const useReactSelectDropdown = (
  props: WrapperProps,
  variant: ReactSelectDropdownVariant = "default"
) => {
  const mounted = React.useRef(false);

  const noResultsFound = RESX.GeneralLabels.NoResultsFound;
  const hasCustomOptions = props.renderOption != null;

  const config = props.config ?? {};

  const reactSelectProps: Props<unknown> = {
    placeholder: config?.placeholder ?? "",
    noOptionsMessage: () => noResultsFound,

    defaultValue: null,
    getOptionValue: props.getLabel,
    getOptionLabel: (opt) => props.getValue(opt)?.toString(),

    value: props.value,
    onChange: props.onChange,
    options: [],

    formatOptionLabel: props.renderOption,

    tabSelectsValue: false,

    openMenuOnFocus: false,
    menuShouldScrollIntoView: false,
    menuPlacement: "auto",

    isClearable: config.isClearable,
    isSearchable: config.isSearchable ?? false,
    isDisabled: config.isDisabled,
    isMulti: false,
    isLoading: config.isLoading,
    className: config.classes,
    controlShouldRenderValue: config.shouldRenderSelectedValue ?? true,
    menuPortalTarget: document.body,
    menuPosition: "absolute",

    loadingMessage: () => RESX.GeneralLabels.Searching,

    components: {
      LoadingIndicator: () => <div style={{ marginRight: 4 }} className="flex justify-center align-center">
        <Loader inline active size="tiny" />
      </div>,
      MenuPortal: (props) => {
        // We use this className to make sure we don't close tooltips when clicking on the dropdown menu
        return <components.MenuPortal {...props} className="react-select-menu-portal" />;
      },
      DropdownIndicator: innerProps => (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...innerProps}>
            {config.isSearchable && <PhosphorMagnifyingGlass iconWeight="bold" iconSize="small" />}
            {!config.isSearchable && <PhosphorCaretDown iconWeight="bold" iconSize="small" />}
          </components.DropdownIndicator>
        )
      ),
      IndicatorSeparator: () => null,
      NoOptionsMessage: noOptsProps => {
        return <components.NoOptionsMessage {...noOptsProps}>
          {noResultsFound}
        </components.NoOptionsMessage>;
      },

      SingleValue: (singleValueProps) => (<components.SingleValue {...singleValueProps}>
        {props.renderSelectedValue != null && props.renderSelectedValue(singleValueProps.data)}
        {props.renderSelectedValue == null && <>
          <div>{props.getLabel(singleValueProps.data)}</div>
        </>}
      </components.SingleValue>),

      // Test the GooglePlacesDropdown if you enable this
      // Without memoizing this, the "input" will be re-rendered on every key press
      // This can cause it to lose focus after every key press
      // Input: props => (
      //     <components.Input {...props} />
      // ),

      ...(!hasCustomOptions
        ? {
          Option: innerProps => (
            <components.Option {...innerProps}>
              <div>{props.getLabel(innerProps.data)}</div>
              {props.getSubLabel && <Sublabel small>
                {props.getSubLabel(innerProps.data)}
              </Sublabel>}
            </components.Option>
          )
        }
        : {}),

      MultiValue: innerProps => (
        <components.MultiValue {...innerProps}>
          <div>{props.getLabel(innerProps.data)}</div>
        </components.MultiValue>
      )
    },

    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: "var(--primary-color)",
        primary25: "var(--primary-color-transparent)",
        neutral80: "var(--text-color-light)",
      }
    }),

    styles: {
      menuPortal: (base) => ({
        ...base,
        borderColor: variant === "borderless" ?
          "transparent" :
          (config.hasError ? "var(--input-error-border)" : undefined),
        zIndex: 9999,
      }),
      control: (base, state) => ({
        ...base,
        backgroundColor: config.hasError ? "var(--input-error-bg)" : "var(--input-bg)",
        borderColor: variant === "borderless" ?
          "transparent" :
          (config.hasError ? "var(--input-error-border)" : state.isFocused ? "var(--border-focus)" : "var(--border-active)"),
        boxShadow: "none",
        borderRadius: "var(--radius-medium)",
        alignItems: "normal",

        minHeight: 0,
        "&:hover": {
          borderColor: variant === "borderless" ?
            "transparent" :
            (config.hasError ? "var(--input-error-border)" : "var(--border-focus)"),
          boxShadow: "none"
        },
        borderBottomLeftRadius: state.menuIsOpen ? "0" : "var(--radius-medium)",
        borderBottomRightRadius: state.menuIsOpen ? "0" : "var(--radius-medium)",
        borderBottom: variant === "borderless" ?
          "transparent" :
          (config.hasError
            ? undefined
            : state.isFocused
              ? "1px solid var(--border-focus)"
              : "1px solid var(--border-active)"),
        cursor: variant === "borderless" ? "pointer" : "default",
      }),
      valueContainer: (base) => ({
        ...base,
        alignItems: "initial",
        padding: "7px 0px 7px 7px",
        lineHeight: "1.21428571em"
      }),
      clearIndicator: (base) => ({
        ...base,
        paddingTop: 5,
        paddingBottom: 5,
        cursor: "pointer",
        "&:hover": {
          color: "var(--hover-color)"
        },
      }),
      multiValue: (base) => ({
        ...base,
        borderRadius: 32,
        backgroundColor: "var(--removable-label-bg)",
        display: "flex",
        alignItems: "center",
        gap: 4,
      }),
      multiValueLabel: (base) => ({
        ...base,
        color: "var(--text-color)"
      }),
      multiValueRemove: (base) => ({
        ...base,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--text-color)",
        cursor: "pointer",
        width: 18,
        height: 18,
        marginRight: 4,
        paddingLeft: 2,
        paddingRight: 2,
        backgroundColor: "var(--removable-label-inner-bg)",
        borderRadius: 9,
        "&:hover": {
          color: "var(--hover-color)",
          backgroundColor: "var(--removable-label-inner-bg)",
        }
      }),
      input: (base) => ({
        ...base,
        padding: 0,
        margin: 0
      }),
      placeholder: (base) => ({
        ...base,
        margin: 0
      }),
      menu: (base, state) => ({
        ...base,
        background: "var(--input-bg)",
        borderRadius: variant === "borderless" ?
          "0.30769231rem" :
          state.placement === "bottom" ? "0em 0em 0.30769231rem 0.30769231rem" : "0.30769231rem 0.30769231rem 0em 0em",
        marginTop: 0,
        marginBottom: 0,
        boxShadow: "none",
        border: config.hasError ? "1px solid var(--input-error-border)" : "1px solid var(--border-active)",
        borderTop: variant === "borderless" ?
          undefined :
          (state.placement === "bottom" ? "none" : undefined),
        borderBottom: state.placement === "top" ? "none" : undefined,
        top: state.placement === "bottom" ? "calc(100% - 1px)" : undefined,
        bottom: state.placement === "top" ? "calc(100% - 1px)" : undefined,
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        color: "inherit",
        transition: "color 300ms ease-in-out",
        opacity: state.isFocused ? 1 : 0.5,
        "&:hover": {
          color: "inherit",
          opacity: state.isFocused ? 1 : 0.5
        },
        lineHeight: 1
      }),
      singleValue: (base) => ({
        ...base,
        margin: 0,
        color: "var(--text-color)"
      })
    }
  };

  const ref = ref => {
    if (config.focusOnMount && !mounted.current) {
      focusOnMount(ref);
      mounted.current = true;
    }
    if (config.inputRef) config.inputRef.current = ref?.inputRef;
  };

  return {
    reactSelectProps,
    ref
  };
};