import { UserAvatar } from "components/Atoms/Avatar";
import { PhosphorListChecks, PhosphorSignOut, PhosphorUserGear } from "components/Atoms/PhosphorIcon";
import React from "react";
import Popup from "reactjs-popup";
import { routes } from "utilities/routing/generatedRoutes";
import { HorizontalKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";

interface ProfileMenuProps {
  currentUser: server.dto.UserSmall
}

const ProfileMenu = (props: ProfileMenuProps) => {
  const isInternalUser = site.currentEnvironment.type === "internal";

  return <>
    <Popup
      trigger={<div {...HorizontalKeyboardNavigationGroup("topBar")} tabIndex={0} className="popup-menu-avatar-icon">
        <UserAvatar size="avatar-32" user={props.currentUser} />
      </div>}
      position="bottom right"
      className="top-bar-popup"
      closeOnDocumentClick
      onOpen={() => { }}
      onClose={() => { }}
      contentStyle={{ padding: "0px", border: "none" }}
      arrow={false}
    >
      <div aria-modal="true" className="popup-menu-wrapper">
        <div className="popup-menu-profile">
          <UserAvatar size="avatar-80" user={props.currentUser} />
          <div className="popup-menu-avatar-info">
            {props.currentUser.fullName}
          </div>
        </div>
        <a href={routes.personal_SettingMenu().absolute} className="popup-menu-item">
          <div className="menu-icon">
            <PhosphorUserGear />
          </div>
          <div className="grow1">{RESX.GeneralLabels.PersonalSettings}</div>
        </a>
        {isInternalUser && (<a href={routes.taskManager_List().absolute} className="popup-menu-item">
          <div className="menu-icon">
            <PhosphorListChecks />
          </div>
          <div className="grow1">{RESX.GeneralLabels.TaskManager}</div>
        </a>)}
        <a
          href={window.CoreApi.Authentication.Signout.absoluteUrl("")}
          className="popup-menu-item"
        >
          <div className="menu-icon">
            <PhosphorSignOut />
          </div>
          <div className="grow1">{RESX.GeneralLabels.SignOut}</div>
        </a>
      </div>
    </Popup>
  </>;
};

export default ProfileMenu;
