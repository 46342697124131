import React from "react";

export const SystemOneSymbol = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M18.7212 11.7019C21.9483 11.7019 24.5643 9.08291 24.5643 5.85222C24.5643 2.62153 21.9483 0.00253296 18.7212 0.00253296C15.4942 0.00253296 12.8782 2.62153 12.8782 5.85222C12.8782 9.08291 15.4942 11.7019 18.7212 11.7019Z" fill="var(--purple)" />
    <path d="M5.84306 0C2.61533 0 0 2.61829 0 5.84968C0 9.08108 2.61533 11.6994 5.84306 11.6994V0Z" fill="var(--green)" />
    <path d="M12.2808 0C9.05307 0 6.43774 2.61829 6.43774 5.84968C6.43774 9.08108 9.05307 11.6994 12.2808 11.6994V0Z" fill="var(--green)" />
    <path d="M5.84306 23.9995C9.0701 23.9995 11.6861 21.3805 11.6861 18.1499C11.6861 14.9192 9.0701 12.3002 5.84306 12.3002C2.61603 12.3002 0 14.9192 0 18.1499C0 21.3805 2.61603 23.9995 5.84306 23.9995Z" fill="var(--blue)" />
    <path d="M18.7214 12.2981C21.9492 12.2981 24.5645 14.9164 24.5645 18.1478C24.5645 21.3792 21.9492 23.9975 18.7214 23.9975V12.2952V12.2981Z" fill="var(--pink)" />
    <path d="M12.2839 12.2981C15.5117 12.2981 18.127 14.9164 18.127 18.1478C18.127 21.3792 15.5117 23.9975 12.2839 23.9975V12.2952V12.2981Z" fill="var(--pink)" />
  </svg>
};