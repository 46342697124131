import React, { ButtonHTMLAttributes, forwardRef, ReactNode, useState } from "react";
import classNames from "classnames";
import { PhosphorBell, PhosphorCaretLeft, PhosphorCaretRight, PhosphorCircleHalf, PhosphorDownloadSimple, PhosphorEnvelope, PhosphorEnvelopeSimple, PhosphorFloppy, PhosphorGear, PhosphorLink, PhosphorLinkBreak, PhosphorList, PhosphorListBullets, PhosphorMagnifyingGlass, PhosphorPencil, PhosphorQuestion, PhosphorSidebar, PhosphorSquaresFour, PhosphorStar, PhosphorTextIndent, PhosphorTrash, PhosphorX } from "./PhosphorIcon";
import { IconWeight } from "@phosphor-icons/react";

interface PhosphorIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  layout: "in-circle" | "in-square" | "none"
  backgroundColor?: "transparent" | "light"
  children?: ReactNode
  selectedStyle?: "blue-bg" | "purple-bg" | "blue-line" | null
  padding?: "regular" | "large"
  iconSize?: "small" | "regular" | "large"
  iconColor?: "gray" | "white"
  iconWeight?: IconWeight;
}

export const PhosphorBellButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorBell {...props} />
  </PhosphorIconButton>
);

export const PhosphorCaretLeftButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorCaretLeft {...props} />
  </PhosphorIconButton>
);

export const PhosphorCaretRightButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorCaretRight {...props} />
  </PhosphorIconButton>
);

export const PhosphorCircleHalfButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorCircleHalf {...props} />
  </PhosphorIconButton>
);

export const PhosphorDownloadSimpleButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorDownloadSimple {...props} />
  </PhosphorIconButton>
);

export const PhosphorEnvelopeButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorEnvelope {...props} />
  </PhosphorIconButton>
);

export const PhosphorEnvelopeSimpleButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorEnvelopeSimple {...props} />
  </PhosphorIconButton>
);

export const PhosphorFloppyButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorFloppy {...props} />
  </PhosphorIconButton>
);

export const PhosphorGearButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorGear {...props} />
  </PhosphorIconButton>
);

export const PhosphorLinkButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorLink {...props} />
  </PhosphorIconButton>
);

export const PhosphorLinkBreakButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorLinkBreak {...props} />
  </PhosphorIconButton>
);

export const PhosphorListButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorList {...props} />
  </PhosphorIconButton>
);

export const PhosphorListBulletsButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorListBullets {...props} />
  </PhosphorIconButton>
);

export const PhosphorManifyingGlassButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorMagnifyingGlass {...props} />
  </PhosphorIconButton>
);

interface NotificationIconButtonProps extends PhosphorIconButtonProps {
  notificationCount: number
}

export const NotificationIconButton = forwardRef<HTMLButtonElement, NotificationIconButtonProps>((props, ref) => {
  const { notificationCount, ...rest } = props;

  return <PhosphorIconButton className="notification-icon-button" {...rest} ref={ref} >
    <PhosphorBell {...props} />
    {notificationCount > 0 && (<div className={classNames("notification-icon-count notification-text", {
      "two-digit": notificationCount > 9 && notificationCount < 100,
      "three-digit": notificationCount > 99
    })}>
      {notificationCount > 99 ? "99+" : notificationCount}
    </div>)}
  </PhosphorIconButton>
});

export const PhosphorPencilButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return <PhosphorIconButton
    {...props}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    ref={ref}
  >
    <PhosphorPencil {...props} iconWeight={isHovered ? "fill" : null} />
  </PhosphorIconButton>
});

export const PhosphorQuestionButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorQuestion {...props} />
  </PhosphorIconButton>
);

export const PhosphorSideBarButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return <PhosphorIconButton
    {...props}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    ref={ref}
  >
    <PhosphorSidebar {...props} iconWeight={isHovered ? "fill" : null} />
  </PhosphorIconButton>
});

export const PhosphorSquaresFourButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorSquaresFour {...props} />
  </PhosphorIconButton>
);

export const PhosphorStarButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return <PhosphorIconButton
    {...props}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    ref={ref}
  >
    <PhosphorStar {...props} iconWeight={isHovered ? "fill" : null} />
  </PhosphorIconButton>
});

export const PhosphorTextIndentButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorTextIndent {...props} />
  </PhosphorIconButton>
);

export const PhosphorTrashButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return <PhosphorIconButton
    {...props}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    ref={ref}
  >
    <PhosphorTrash {...props} iconWeight={isHovered ? "fill" : null} />
  </PhosphorIconButton>
});

const PhosphorIconButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) => {
  const { iconSize, backgroundColor, ...rest } = props;

  const classes = classNames("phosphor-icon-button", props.className, props.iconSize || "large", {
    "in-circle": props.layout === "in-circle",
    "in-square": props.layout === "in-square",
    "padding-large": props.padding === "large",

    "light-bg": props.backgroundColor === "light",

    "blue-line": props.selectedStyle === "blue-line",
    "blue-bg": props.selectedStyle === "blue-bg",
    "purple-bg": props.selectedStyle === "purple-bg",
  });

  return <div>
    <button
      {...rest}
      ref={ref}
      type="button"
      className={classes}
    >
      {props.children}
    </button>
  </div>
});

export const PhosphorXButton = forwardRef<HTMLButtonElement, PhosphorIconButtonProps>((props, ref) =>
  <PhosphorIconButton {...props} ref={ref} >
    <PhosphorX {...props} iconWeight="bold" />
  </PhosphorIconButton>
);