import { PhosphorGlobe } from "components/Atoms/PhosphorIcon";
import { PhosphorQuestionButton } from "components/Atoms/PhosphorIconButton";
import { HorizontalKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";
import React from "react";
import Popup from "reactjs-popup";

const FaqMenu = () => {
  return <>
    <Popup
      className="top-bar-popup"
      trigger={<PhosphorQuestionButton
        {...HorizontalKeyboardNavigationGroup("topBar")}
        layout="in-circle"
        iconSize="large" />}
      position="bottom right"
      closeOnDocumentClick
      onOpen={() => { }}
      onClose={() => { }}
      contentStyle={{ padding: "0px", border: "none" }}
      arrow={false}
    >
      <div aria-modal="true" className="popup-menu-wrapper">
        <a href="https://systemonesoftware.com/support-center" className="popup-menu-item">
          <div className="menu-icon"><PhosphorGlobe /></div>
          <div className="grow1">
            Support Articles
          </div>
        </a>
        <a href="https://systemonesoftware.com/blog" className="popup-menu-item">
          <div className="menu-icon"><PhosphorGlobe /></div>
          <div className="grow1">
            Blog
          </div>
        </a>
        <a href="https://www.systemonesoftware.com/changelog" className="popup-menu-item">
          <div className="menu-icon"><PhosphorGlobe /></div>
          <div className="grow1">
            Change log
          </div>
        </a>
      </div>
    </Popup>
  </>;
};

export default FaqMenu;
