import React from "react";

interface SystemOneWordMarkProps {
  fillColor?: string
}

export const SystemOneWordMark = (props: SystemOneWordMarkProps) => {
  const fillColor = props.fillColor || "var(--text-color)";

  return <svg xmlns="http://www.w3.org/2000/svg" width="93" height="16" viewBox="0 0 93 16" fill="none">
    <path d="M18.9813 4.14285L14.0702 15.9252H11.3542L12.9903 12.2673L9.22412 4.14285H11.9607L14.2861 9.31427L16.2476 4.14285H18.9813Z" fill={fillColor} />
    <path d="M18.8745 10.0525H21.1792C21.2324 10.6656 21.9159 11.1158 22.7442 11.1158C23.5726 11.1158 24.0578 10.79 24.0578 10.3042C24.0578 8.70185 19.055 10.2509 19.055 6.68185C19.055 5.20388 20.3686 3.96286 22.5105 3.96286C24.4365 3.96286 26.0903 4.99063 26.3063 6.86253H23.8774C23.8064 6.35901 23.2294 5.96212 22.546 5.96212C21.8094 5.96212 21.3774 6.24942 21.3774 6.68185C21.3774 8.21314 26.398 6.39455 26.398 10.3042C26.398 11.9244 24.8862 13.1683 22.907 13.1683C20.623 13.1683 19.055 11.9984 18.8775 10.0525H18.8745Z" fill={fillColor} />
    <path d="M31.2169 6.5576V9.35064C31.2169 10.3044 31.8649 10.719 32.7465 10.719C32.9625 10.719 33.1962 10.7013 33.3944 10.6479V12.9908C33.0512 13.0796 32.5305 13.1359 32.1696 13.1359C30.0809 13.1359 28.6785 11.7675 28.6785 9.38914V6.56056H27.0425V4.14664H28.6785V1.82158H31.2169V4.14664H33.3944V6.56056H31.2169V6.5576Z" fill={fillColor} />
    <path d="M42.9328 9.16982H36.3649C36.5454 10.322 37.427 11.0625 38.6134 11.0625C39.6045 11.0625 40.2317 10.63 40.3766 10.0169H42.8588C42.5719 11.9273 40.8973 13.1683 38.6844 13.1683C36.075 13.1683 34.0425 11.1335 34.0425 8.52117C34.0425 5.90881 36.0572 3.96286 38.6311 3.96286C41.0246 3.96286 42.9861 5.83772 42.9861 8.26941C42.9861 8.46786 42.9683 8.88252 42.9328 9.16982ZM40.3411 7.54968C40.2701 6.64927 39.4595 6.0184 38.5246 6.0184C37.5897 6.0184 36.7969 6.50414 36.4921 7.54968H40.3441H40.3411Z" fill={fillColor} />
    <path d="M58.2462 7.4934V12.9877H55.69V8.01469C55.69 6.95138 55.0954 6.30273 54.1605 6.30273C53.5303 6.30273 52.8824 6.69962 52.5777 7.23868V12.9847H50.0393V8.01173C50.0393 6.94842 49.4091 6.29977 48.563 6.29977C47.8263 6.29977 47.2139 6.71443 46.9447 7.292V12.9847H44.4062V4.14057H46.9447V5.15057C47.3588 4.57301 48.205 3.96286 49.4623 3.96286C50.5777 3.96286 51.3883 4.32421 51.9268 5.20684C52.4652 4.57597 53.4563 3.96286 54.6249 3.96286C56.7314 3.96286 58.2432 5.36678 58.2432 7.4934H58.2462Z" fill={fillColor} />
    <path d="M72.6097 6.68162C72.6097 10.3751 69.8376 13.1681 66.169 13.1681C62.5005 13.1681 59.7461 10.3751 59.7461 6.68162C59.7461 2.98817 62.5005 0.195129 66.169 0.195129C69.8376 0.195129 72.6097 2.98817 72.6097 6.68162ZM62.4798 6.68162C62.4798 8.89709 64.0626 10.5735 66.169 10.5735C68.2755 10.5735 69.8583 8.89709 69.8583 6.68162C69.8583 4.46614 68.2755 2.78972 66.169 2.78972C64.0626 2.78972 62.4798 4.46614 62.4798 6.68162Z" fill={fillColor} />
    <path d="M82.6512 7.97915V12.9877H80.1128V8.41158C80.1128 7.18537 79.4471 6.48341 78.4027 6.48341C77.7016 6.48341 77.0004 6.8803 76.6395 7.4934V12.9877H74.1011V4.14353H76.6395V5.18907C77.1247 4.4338 78.0596 3.96286 79.0507 3.96286C81.2814 3.96286 82.6482 5.49414 82.6482 7.97915H82.6512Z" fill={fillColor} />
    <path d="M92.9465 9.16982H86.3786C86.5591 10.322 87.4407 11.0625 88.6271 11.0625C89.6182 11.0625 90.2454 10.63 90.3903 10.0169H92.8725C92.5855 11.9273 90.911 13.1683 88.6981 13.1683C86.0887 13.1683 84.0562 11.1335 84.0562 8.52117C84.0562 5.90881 86.0709 3.96286 88.6448 3.96286C91.0382 3.96286 92.9997 5.83772 92.9997 8.26941C92.9997 8.46786 92.982 8.88252 92.9465 9.16982ZM90.3548 7.54968C90.2838 6.64927 89.4732 6.0184 88.5383 6.0184C87.6034 6.0184 86.8105 6.50414 86.5058 7.54968H90.3578H90.3548Z" fill={fillColor} />
    <path d="M2.82159 3.91012C2.82159 3.17261 3.45175 2.72241 4.45764 2.72241C5.46354 2.72241 6.32447 3.3207 6.45168 4.1441H9.22381C8.8037 1.98787 7.38953 0.198898 4.39552 0.198898C1.91332 0.198898 0.212179 1.76572 0.212179 3.92789C0.212179 8.52174 6.83333 7.026 6.83333 9.27702C6.83333 10.1063 6.0227 10.6454 4.85408 10.6454C3.59376 10.6454 2.71212 9.92567 2.71212 8.80904H0.0849609C0.0849609 11.3503 2.08196 13.1689 4.85408 13.1689C7.76822 13.1689 9.47824 11.2585 9.47824 9.27702C9.47824 4.50249 2.82159 6.03377 2.82159 3.91012Z" fill={fillColor} />
  </svg>;
}