import classNames from "classnames";
import React from "react";
import { Modal, type ModalProps } from "semantic-ui-react";
export type ModalWidth = "fullscreen" | "huge" | "large" | "mini" | "small" | "tiny";

export interface ISystemOneModalProps extends Omit<ModalProps, "open"> {
  handleClose: (event?: React.MouseEvent<HTMLElement>, data?: ModalProps) => void
  isOpen?: boolean
  className?: string
  titleClassName?: string
  width?: ModalWidth
  title?: string
  minHeight?: number
  height?: number | "max"
  displayHeader?: boolean
  hasAutoScrollBox?: boolean // content inside modal has scrollbox
  gap?: "small" | "regular" | "larger" | "bigger"
  onOpen?: () => void
  headerButton?: JSX.Element
}

class SystemOneModal extends React.Component<ISystemOneModalProps> {
  componentDidUpdate(prevProps: Readonly<ISystemOneModalProps>): void {
    if (prevProps.isOpen && this.props.onOpen) {
      this.props.onOpen();
    }
  }

  public render(): JSX.Element {
    // we destructure here to remove props from rest
    const {
      handleClose,
      displayHeader = true,
      headerButton,
      titleClassName,
      isOpen,
      width: size = "mini",
      className,
      title,
      hasAutoScrollBox,
      minHeight,
      height,
      maxHeight,
      ...rest
    } = this.props;

    const newMinHeight = minHeight ?? 100;

    let newHeight: string = null;

    if (typeof height === "number") {
      newHeight = `${height}px`;
    } else if (height === "max") {
      newHeight = "calc(-50px + 100vh)";
    } else {
      newHeight = height;
    }

    const headerClasses = classNames(
      {
        "margin-right": hasAutoScrollBox
      });

    const headerElement = <div className={headerClasses}>
      <div className="flex">
        <div className="grow1">
          {title != null && <h1 className={titleClassName}>{title}</h1>}
        </div>
        {headerButton || <div onClick={handleClose}>
          <a className="icon-x"></a>
        </div>}
      </div>
    </div>;

    const innerContainerClasses = classNames("flex-column", {
      "padding-bigger": !hasAutoScrollBox,
      "padding-top-bigger": hasAutoScrollBox,
      "padding-right-larger": hasAutoScrollBox,
      "padding-bottom-bigger": hasAutoScrollBox,
      "padding-left-bigger": hasAutoScrollBox,
      "gap-bigger": this.props.gap === "bigger" || this.props.gap === undefined,
      gap: this.props.gap === "regular",
      "gap-small": this.props.gap === "small",
      "gap-larger": this.props.gap === "larger"
    });

    const modalClasses = classNames(className, size);

    return (
      <Modal
        {...rest}
        open={isOpen}
        onClose={handleClose}
        className={modalClasses}
      >
        <div className={innerContainerClasses} style={{ minHeight: newMinHeight, height: newHeight, maxHeight: "calc(-50px + 100vh)" }}>
          {displayHeader && headerElement}
          {this.props.children}
        </div>

      </Modal>
    );
  }
}

export default SystemOneModal;
