const getTopLevelDomain = () => {
  const domainParts = window.location.hostname.split(".");
  return domainParts.slice(-2).join(".");
}

// Set the domain level theme cookie
const setThemeCookie = (value: server.dto.Themes) => {
  document.cookie = `theme=${value}; domain=${getTopLevelDomain()}; path=/`;
}

// Get the domain level theme cookie
const getThemeCookie = (): server.dto.Themes => {
  const cookie = document.cookie.split(";").find((cookie) => cookie.includes("theme"));
  return cookie?.split("=")[1] as server.dto.Themes;
}

// Map of themes and the ids of stylesheets
const themeIds: Record<server.dto.Themes, string> = {
  "dark": "dark-theme-variables",
  "light": "light-theme-variables"
}

// Gets the system default theme
const getSystemTheme = (): server.dto.Themes => {
  const darkThemeMq = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
  return darkThemeMq ? "dark" : "light";
}

/**
 * Get the currently set theme
 */
export const getTheme = (): server.dto.Themes => {
  return getThemeCookie() as server.dto.Themes || getSystemTheme();
}

// Returns the CKEditor skin name based on the current theme
export const getCKEditorTheme = (): string => isDark() ? "prestige" : "minimalist";
export const getCKEditorContentCss = (): string => isDark() ? "contents_dark.css" : "contents_light.css";

// Is the current theme considered a dark theme
const isDark = () => getTheme() === "dark";

/**
 * Load the current theme from the cookie and apply it to the document
 */
export const loadTheme = () => {
  setTheme(getTheme());
  updateMetaThemeColor();
}

/**
 * Change the current theme
 */
export const setTheme = (theme: server.dto.Themes) => {
  Object.values(themeIds).forEach((id) => {
    document.getElementById(id)?.setAttribute("disabled", "true");
  });

  const themeId = themeIds[theme];
  const themeElement = document.getElementById(themeId);
  if (themeElement) {
    themeElement.removeAttribute("disabled");
  }

  setThemeCookie(theme);
}

export const updateMetaThemeColor = () => {
  const themeColorMetaTag = document.querySelector("meta[name=\"theme-color\"]");

  if (themeColorMetaTag) {
    const themeColor = getComputedStyle(document.documentElement).getPropertyValue("--panel-bg").trim();
    themeColorMetaTag.setAttribute("content", themeColor);
  }
}