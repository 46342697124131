import React, { useEffect, useState } from "react";
import { useLocalStorage } from "utilities/usehooks";

export interface TwerkingChipProps {
  position: "top" | "bottom";
  label: string;
  offset?: number;
  id: string;
}

export const TwerkingChip = (props: React.PropsWithChildren<TwerkingChipProps>) => {
  const [visible, setVisible] = useLocalStorage(`twerkingChip:${props.id}`, true);
  const [style, setStyle] = useState({
    left: 0,
    top: 0,
  });
  const { id, label, children } = props;

  useEffect(() => {
    const element = document.getElementById(`twerking-chip-${id}`);

    // When the element is not present, it means the twerking chips is dismissed
    if (!element) {
      return;
    }

    const childElements = document.getElementById(`twerking-chip-${id}`)?.children || [];

    if (childElements.length < 2) {
      throw new Error("TwerkingChip must have at least 1 child");
    }

    const chipChild = childElements[0].getBoundingClientRect();
    const firstChild = childElements[1].getBoundingClientRect();
    const offset = props.offset || 10;

    switch (props.position) {
      case "top":
        setStyle({
          left: firstChild.width / 2 - chipChild.width / 2,
          top: -chipChild.height - offset,
        });
        break;
      case "bottom":
        setStyle({
          left: firstChild.width / 2 - chipChild.width / 2,
          top: firstChild.height + offset,
        });
    }

    element.addEventListener("click", () => {
      onDismiss();
    }, { capture: true });
  }, []);

  const onDismiss = () => {
    setVisible(false);
  };

  if (!visible) {
    return <>{children}</>;
  }

  return <div id={`twerking-chip-${id}`} className="twerking-chip-wrapper">
    <div style={{
      ...style,
      animationDelay: `${Math.random() * 6}s`,
    }} className="twerking-chip">
      {label}
    </div>
    {children}
  </div>
};
