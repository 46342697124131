import React from "react";
import ReactSelect, { type GroupBase } from "react-select";
import { type CommonReactSelectWrapperProps } from "../models";
import { ReactSelectDropdownVariant, useReactSelectDropdown } from "../hooks";

interface ReactSelectWrapperProps<TOption, IsMulti extends boolean = false, TId = number> extends CommonReactSelectWrapperProps<TOption, IsMulti, TId> {
  options: TOption[] | Array<GroupBase<TOption>>
  variant?: ReactSelectDropdownVariant
}

const BaseReactSelectDropdown = <TOption extends object, IsMulti extends boolean = false, TId = number>(props: ReactSelectWrapperProps<TOption, IsMulti, TId>) => {
  const reactSelect = useReactSelectDropdown(props, props.variant);

  let options = props.options;
  if (props.excludeKeys != null) {
    if (options[0] != null && "options" in options[0] && "label" in options[0]) {
      throw new Error("Cannot use exclude keys with grouped options");
    }
    const set = new Set(props.excludeKeys);
    options = (props.options as TOption[]).filter(o => !set.has(props.getValue(o)));
  }

  return <div className="flex">
    <div className="grow1">
      <ReactSelect
        {...reactSelect.reactSelectProps}
        ref={reactSelect.ref}
        options={options}
      />
    </div>
  </div>;
};

export default BaseReactSelectDropdown;