import { NotificationIconButton } from "components/Atoms/PhosphorIconButton";
import { ButtonText } from "components/Atoms/Button";
import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import { routes } from "utilities/routing/generatedRoutes";
import NotificationList from "./NotificationList";
import { useNotificationCount } from "./useNotificationCount";
import { HorizontalKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";

interface NotificationMenuProps {
  notificationCount: number
}

const NotificationMenu = (props: NotificationMenuProps) => {
  const [originalTitle] = React.useState(document.title);
  const { badge, onNotificationsListOpen } = useNotificationCount(props.notificationCount);

  useEffect(() => {
    if (badge > 0) {
      document.title = `(${badge}) ${originalTitle}`;
    } else {
      document.title = originalTitle;
    }
  }, [badge]);

  return <>
    <Popup
      trigger={<NotificationIconButton
        {...HorizontalKeyboardNavigationGroup("topBar")}
        notificationCount={badge}
        layout="in-circle"
        iconSize="large" />}
      className="top-bar-popup"
      position="bottom right"
      closeOnDocumentClick
      onOpen={() => { }}
      onClose={() => { }}
      contentStyle={{ padding: "0px", border: "none" }}
      arrow={false}
    >
      <div aria-modal="true" className="popup-menu-wrapper" style={{ minWidth: 600 }}>
        <div className="popup-menu-header">
          <div className="info-text-medium">{RESX.Notifications.resxNotifications}</div>
          <ButtonText
            label={RESX.NotificationSettings.resxNotificationSettings}
            href={routes.preference_NotificationSettings().absolute}
          />
        </div>
        <NotificationList onNotificationsListOpen={onNotificationsListOpen} />
      </div>
    </Popup>
  </>;
};

export default NotificationMenu;
