import { KeyboardNavigationOptions, navigationOptionsToProps } from "./model"

export const HorizontalKeyboardNavigationGroup = (name: string) => {
  const options: KeyboardNavigationOptions = {
    navigationGroup: name,
    navigationGroupDirection: "horizontal",
    navigationGroupLeaveOnTab: true,
    focusShiftOnEnter: false,
    spaceEnabled: false,
    navigationDisabled: false
  }

  return navigationOptionsToProps(options)
}

export const VerticalKeyboardNavigationGroup = (name: string) => {
  const options: KeyboardNavigationOptions = {
    navigationGroup: name,
    navigationGroupDirection: "vertical",
    navigationGroupLeaveOnTab: true,
    focusShiftOnEnter: false,
    spaceEnabled: false,
    navigationDisabled: false
  }

  return navigationOptionsToProps(options)
}

export const SelectableListKeyboardNavigationGroup = (name: string) => {
  const options: KeyboardNavigationOptions = {
    navigationGroup: name,
    navigationGroupDirection: "vertical",
    navigationGroupLeaveOnTab: true,
    focusShiftOnEnter: true,
    spaceEnabled: true,
    navigationDisabled: false
  }

  return navigationOptionsToProps(options)
}

export const SelectableListKeyboardNavigationGroupHorizontal = (name: string) => {
  const options: KeyboardNavigationOptions = {
    navigationGroup: name,
    navigationGroupDirection: "horizontal",
    navigationGroupLeaveOnTab: true,
    focusShiftOnEnter: true,
    spaceEnabled: true,
    navigationDisabled: false
  }

  return navigationOptionsToProps(options)
}