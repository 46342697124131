import React, { useState } from "react";
import { SystemOneSymbol } from "../Atoms/brand/SystemOneSymbol";
import { SystemOneWordMark } from "../Atoms/brand/SystemOneWordMark";
import { PhosphorTextIndentButton } from "components/Atoms/PhosphorIconButton";

interface SystemOneLogoSideNavProps {
  collapsed: boolean
  onOpen: () => void
}

export const SystemOneLogoSideNav = (props: SystemOneLogoSideNavProps) => {
  const { collapsed, onOpen } = props;
  const [hovered, setHovered] = useState(false);

  if (hovered && collapsed) {
    return <span
      className="grow1"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <PhosphorTextIndentButton
        layout="in-square"
        onClick={onOpen}
        iconSize="large"
      />
    </span>
  }

  return <a
    href={site.legacyPageBaseUrl}
    className="grow1 flex gap-large align-center"
    onMouseOver={() => setHovered(true)}
    onMouseOut={() => setHovered(false)}
  >
    <SystemOneSymbol />
    {!collapsed && <SystemOneWordMark />}
  </a>
};