import { customRules } from "./custom"

export type KeyboardNavigationOptions = {
  navigationGroup: string
  navigationGroupDirection: "horizontal" | "vertical"
  navigationGroupLeaveOnTab: boolean
  focusShiftOnEnter: boolean
  spaceEnabled: boolean
  navigationDisabled: boolean
}

const DefaultKeyboardNavigationOptions: KeyboardNavigationOptions = {
  navigationGroup: "",
  navigationGroupDirection: "vertical",
  navigationGroupLeaveOnTab: true,
  focusShiftOnEnter: false,
  spaceEnabled: false,
  navigationDisabled: false
}

export const getNavigationOptionsFromElement = (element: HTMLElement): KeyboardNavigationOptions => {
  // Handle custom rules. This is useful for when we can't configure the keyboard navigation on a given
  //  component directly, like a react-select dropdown.
  for (const rule of customRules) {
    if (rule.condition(element)) {
      return rule.options;
    }
  }

  const navigationGroup = element.getAttribute("data-navigation-group") || "";

  const navigationGroupDirection = element.getAttribute("data-navigation-group-direction") || "vertical";
  if (navigationGroupDirection !== "horizontal" && navigationGroupDirection !== "vertical") {
    return DefaultKeyboardNavigationOptions
  }

  const navigationGroupLeaveOnTab = (element.getAttribute("data-navigation-group-leave-on-tab") || "true") === "true";

  const focusShiftOnEnter = element.getAttribute("data-focus-shift-on-enter") === "true";
  const spaceEnabled = element.getAttribute("data-space-enabled") === "true";
  const navigationDisabled = element.getAttribute("data-navigation-disabled") === "true";

  return {
    navigationGroup,
    navigationGroupDirection,
    navigationGroupLeaveOnTab,
    focusShiftOnEnter,
    spaceEnabled,
    navigationDisabled
  }
}

export const navigationOptionsToProps = (options: KeyboardNavigationOptions) => ({
  "data-navigation-group": options.navigationGroup,
  "data-navigation-group-direction": options.navigationGroupDirection,
  "data-navigation-group-leave-on-tab": options.navigationGroupLeaveOnTab,
  "data-focus-shift-on-enter": options.focusShiftOnEnter,
  "data-space-enabled": options.spaceEnabled,
  "data-navigation-disabled": options.navigationDisabled
})