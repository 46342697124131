import classNames from "classnames";
import React from "react";
import { Loader, SemanticSIZES } from "semantic-ui-react";

interface IProps {
  height?: number
  className?: string
  inverted?: boolean
  size?: SemanticSIZES
  dontAlignCenter?: boolean
}

class Loading extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    const { height, inverted, size, className } = this.props;

    const wrapperClass = classNames("content flex", className || "", {
      "align-center": !this.props.dontAlignCenter
    });

    let style = {};

    if (height) {
      style = { height };
    }

    return (
      <div className={wrapperClass} style={style}>
        <Loader active inline="centered" inverted={inverted} size={size || "small"} />
      </div>
    );
  }
}

export default Loading;
