import {
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import { KeyboardNavigationProvider } from "keyboardNavigation/KeyboardNavigationSystem";
import React from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const DefaultProviders = (props: React.PropsWithChildren<unknown>) => {
  return (
    <QueryClientProvider client={queryClient}>
      <KeyboardNavigationProvider>
        {props.children}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </KeyboardNavigationProvider>
    </QueryClientProvider>
  );
};

export default DefaultProviders;