import React from "react";
import { getNavigationOptionsFromElement, KeyboardNavigationOptions } from "./model";
import { getNextElement, getNextElementOutsideGroup, getNextElementToFocusOnSelect, getPreviousElement, getPreviousElementOutsideGroup } from "./utils";

const jumpFocusToNextElementOutsideGroup = (group: string) => {
  const nextElement = getNextElementOutsideGroup(group);
  if (nextElement) {
    nextElement.focus();
  }
}

const jumpFocusToPreviousElementOutsideGroup = (group: string) => {
  const previousElement = getPreviousElementOutsideGroup(group);
  if (previousElement) {
    previousElement.focus();
  }
}

/**
 * Provider that enables advanced keyboard navigation.
 * The ruleset this system applies is the following:
 *  - *ArrowUp*: Focuses the previous element in the group, if there is no previous element, it will jump to the previous group.
 *   Acts as tab for horizontal groups.
 *  - *ArrowDown*: Focuses the next element in the group, if there is no next element, it will jump to the next group.
 *   Acts as shift+tab for horizontal groups.
 *  - *ArrowLeft*: Focuses the previous element in the group, if the group is horizontal.
 *   Does nothing if the group is vertical or there is no previous element.
 *  - *ArrowRight*: Focuses the next element in the group, if the group is horizontal.
 *   Does nothing if the group is vertical or there is no next element.
 *  - Tab & Shift-Tab: Jumps to the next or previous group.
 *  - Enter: Clicks the active element.
 */
export const KeyboardNavigationProvider = (props: React.PropsWithChildren<any>) => {
  const { children } = props;
  const onArrowUp = (event: React.KeyboardEvent, options: KeyboardNavigationOptions) => {
    event.preventDefault();

    if (options.navigationGroupDirection === "horizontal") {
      jumpFocusToPreviousElementOutsideGroup(options.navigationGroup);
      return;
    }

    const previousElement = getPreviousElement(options.navigationGroup);
    if (previousElement) {
      previousElement.focus();
    } else {
      jumpFocusToPreviousElementOutsideGroup(options.navigationGroup);
    }
  }

  const onArrowDown = (event: React.KeyboardEvent, options: KeyboardNavigationOptions) => {
    event.preventDefault();

    if (options.navigationGroupDirection === "horizontal") {
      jumpFocusToNextElementOutsideGroup(options.navigationGroup);
      return;
    }

    const nextElement = getNextElement();
    if (nextElement) {
      nextElement.focus();
    } else {
      jumpFocusToNextElementOutsideGroup(options.navigationGroup);
    }
  }

  const onArrowLeft = (options: KeyboardNavigationOptions) => {
    if (options.navigationGroupDirection === "horizontal") {
      const previousElement = getPreviousElement(options.navigationGroup);
      if (previousElement) {
        previousElement.focus();
      }
    }
  }

  const onArrowRight = (options: KeyboardNavigationOptions) => {
    if (options.navigationGroupDirection === "horizontal") {
      const nextElement = getNextElement(options.navigationGroup);
      if (nextElement) {
        nextElement.focus();
      }
    }
  }

  const onTab = (e: React.KeyboardEvent, options: KeyboardNavigationOptions) => {
    if (options.navigationGroupLeaveOnTab) {
      e.preventDefault();
      if (e.shiftKey) {
        jumpFocusToPreviousElementOutsideGroup(options.navigationGroup);
      } else {
        jumpFocusToNextElementOutsideGroup(options.navigationGroup);
      }
    }
  }

  const onEnter = (options: KeyboardNavigationOptions) => {
    const shiftFocusTo = options.focusShiftOnEnter ? getNextElementToFocusOnSelect(options.navigationGroup) : null;

    const activeElement = document.activeElement as HTMLElement;
    activeElement?.click();

    if (shiftFocusTo) {
      shiftFocusTo.focus();
    }
  }

  const onKeyDown = (event: React.KeyboardEvent) => {
    const activeElement = document.activeElement as HTMLElement;
    const options = getNavigationOptionsFromElement(activeElement);

    if (options.navigationDisabled && event.key !== "Tab" && event.key !== "Enter") {
      return;
    }

    if (event.key === "ArrowUp") {
      onArrowUp(event, options);
    } else if (event.key === "ArrowDown") {
      onArrowDown(event, options);
    } else if (event.key === "ArrowLeft") {
      onArrowLeft(options);
    } else if (event.key === "ArrowRight") {
      onArrowRight(options);
    } else if (event.key === "Enter") {
      onEnter(options);
    } else if (event.key === "Tab") {
      onTab(event, options);
    } else if (event.key === " " && options.spaceEnabled) {
      event.preventDefault();
      onEnter(options);
    }
  }

  return <div style={{ display: "contents" }} onKeyDown={onKeyDown}>
    {children}
  </div>
}