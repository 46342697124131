import classNames from "classnames";
import { PhosphorSideBarButton } from "components/Atoms/PhosphorIconButton";
import { SystemOneLogoSideNav } from "components/SidebarNavigation/SystemOneLogoSideNav";
import { VerticalKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOnClickOutside, useViewport } from "utilities/hookutils";
import { useShortcut } from "utilities/shortcutSystem";
import { useLocalStorage } from "utilities/usehooks";
import { isViewport } from "utilities/viewportUtils";

interface MainNavigationProps {
  navigation: server.dto.Navigation
  onMore: () => void
  isMoreOpen: boolean
  collapsable?: boolean
  isCollapsed?: boolean
  isCollapsing?: boolean
  onCollapse?: () => void
}

const MainNavigationComponent = (props: MainNavigationProps) => {
  const { navigation, onMore, isMoreOpen, collapsable, isCollapsed, isCollapsing, onCollapse } = props;

  const visibleMenus = navigation.mainMenu.filter((item) => !item.hidden);
  const activeMenu = isMoreOpen ? "more" : navigation.chosenMain;

  useEffect(() => {
    if (isCollapsed) {
      document.body.classList.add("collapsed-sidebar");
    } else {
      document.body.classList.remove("collapsed-sidebar");
    }
    if (isCollapsed && isCollapsing) {
      document.body.classList.add("expanding-sidebar");
    } else {
      document.body.classList.remove("expanding-sidebar");
    }
    if (!isCollapsed && isCollapsing) {
      document.body.classList.add("collapsing-sidebar");
    } else {
      document.body.classList.remove("collapsing-sidebar");
    }
  }, [isCollapsed, isCollapsing]);

  useShortcut("key-[", () => {
    if (collapsable) {
      onCollapse();
    }
  }, [isCollapsed, isCollapsing, onCollapse]);

  return <div className={classNames("sidebar-main-nav-panel-wrapper", {
    collapsed: collapsable && isCollapsed,
    collapsing: collapsable && !isCollapsed && isCollapsing,
    expanding: collapsable && isCollapsed && isCollapsing
  })}>
    <div className="sidebar-main-nav-container">
      <div className={classNames("sidebar-main-nav-logo", "flex", "align-center", {
        collapsed: collapsable && isCollapsed,
        collapsing: collapsable && !isCollapsed && isCollapsing,
        expanding: collapsable && isCollapsed && isCollapsing
      })}>
        <SystemOneLogoSideNav
          collapsed={collapsable && isCollapsed && !isCollapsing}
          onOpen={onCollapse}
        />
        {(collapsable && !isCollapsed) && <PhosphorSideBarButton
          layout="none"
          iconSize="large"
          onClick={onCollapse}
        />}
      </div>
      <div className={classNames("sidebar-main-nav-menu", {
        collapsed: collapsable && isCollapsed,
        collapsing: collapsable && !isCollapsed && isCollapsing,
        expanding: collapsable && isCollapsed && isCollapsing
      })}>
        {visibleMenus.map((item) =>
          <a {...VerticalKeyboardNavigationGroup("mainNavigation")} key={item.key} href={item.url}
            className={classNames("sidebar-main-nav-menu-item subtitle-text-regular", {
              "active-menu": item.key === activeMenu
            })}>
            {item.label}
          </a>
        )}
        {(navigation.moreMenu?.length > 0) && (
          <button {...VerticalKeyboardNavigationGroup("mainNavigation")} key="more-button" type="button" onClick={onMore}
            className={classNames("sidebar-main-nav-menu-item-button subtitle-text-regular", {
              "active-menu": activeMenu === "more"
            })}>
            {RESX.Planning.More}
          </button>
        )}
      </div>
    </div>
  </div >;
};

const MainNavigation = (props: MainNavigationProps) => {
  const { navigation, onMore, isMoreOpen } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useLocalStorage("sidebar:collapsed", false);
  const [isCollapsing, setIsCollapsing] = useState(false);

  const viewportType = useViewport();

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (isOpen && !isMoreOpen) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    window.onSideNavOpen = () => {
      setIsOpen(true);
    };
  }, []);

  const onCollapse = useCallback(() => {
    if (isCollapsing) {
      return;
    }

    setIsCollapsing(true);
    setTimeout(() => {
      setIsCollapsed(!isCollapsed);
      setIsCollapsing(false);
    }, 300);
  }, [isCollapsed, isCollapsing]);

  if (isViewport(viewportType).smallerThan("desktopMedium")) {
    return <div ref={ref} className={
      classNames("sidebar-main-nav-panel-mobile-wrapper", {
        open: isOpen
      })}>
      <MainNavigationComponent isMoreOpen={isMoreOpen} navigation={navigation} onMore={onMore} />
    </div>;
  }

  return <MainNavigationComponent
    isMoreOpen={isMoreOpen}
    navigation={navigation}
    onMore={onMore}
    collapsable={isViewport(viewportType).smallerThan("desktopUltraWide")}
    isCollapsed={isViewport(viewportType).smallerThan("desktopUltraWide") && isCollapsed}
    isCollapsing={isViewport(viewportType).smallerThan("desktopUltraWide") && isCollapsing}
    onCollapse={() => { onCollapse(); }}
  />;
};

export default MainNavigation;