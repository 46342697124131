import React from "react";
import FaqMenu from "./FaqMenu";
import SettingsMenu from "./SettingsMenu";
import NotificationMenu from "./NotificationMenu";
import ProfileMenu from "./ProfileMenu";
import { useViewport } from "utilities/hookutils";
import { PhosphorListButton } from "components/Atoms/PhosphorIconButton";
import { isViewport } from "utilities/viewportUtils";
import { ThemeSelectMenu } from "./ThemeSelectMenu";
import { TwerkingChip } from "components/Atoms/twerkingChip/TwerkingChip";

interface TopBarProps {
  notificationCount: number
  currentUser: server.dto.UserSmall
  environmentType: server.dto.Environments
  onMenuOpen: () => void
}

const TopBar = (props: TopBarProps) => {
  const viewportType = useViewport();

  const isSmallScreen = isViewport(viewportType).smallerThan("desktopMedium");

  return <>
    {isSmallScreen && <div className="top-bar-wrapper-placeholder" />}
    <div className="top-bar-wrapper">
      {isSmallScreen && <PhosphorListButton
        layout="in-square"
        iconSize="large"
        onClick={props.onMenuOpen}
      />}
      <span className="top-bar-middle" />
      {props.environmentType === "internal" && <FaqMenu />}
      <TwerkingChip offset={-4} position="bottom" id="themeSelection" label={RESX.Buttons.btnNew}>
        <ThemeSelectMenu />
      </TwerkingChip>
      <NotificationMenu notificationCount={props.notificationCount} />
      <SettingsMenu />
      <ProfileMenu currentUser={props.currentUser} />
    </div>
  </>;
};

export default TopBar;
