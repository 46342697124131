import { ButtonAccent } from "components/Atoms/Button";
import AppForEsign from "components/EsignOrLegacy/documentlist/appForEsign";
import React from "react";
import { useCustomQuery } from "utilities/hookutils";

const NewQuote = () => {
  const bookkeepingsApi = useCustomQuery({
    queryFn: async () => window.CoreApi.Bookkeeping.GetBookkeepings(),
    queryKey: ["bookkeepings"]
  });

  const brandsApi = useCustomQuery({
    queryFn: () => window.CoreApi.Brand.List({ page: 1, perPage: 50 }),
    queryKey: ["brands"],
  });

  return <>
    <ButtonAccent
      label={RESX.GeneralLabels.resxNew}
      onClick={() => (window as any).createNewDocument("")}
    />
    <AppForEsign
      documentType="quote"
      bookkeepings={bookkeepingsApi.data?.data ?? []}
      brands={brandsApi.data?.data?.data ?? []}
    />
  </>;
};

export default NewQuote;
