import React from "react";
import ReduxDefaultProviders from "./ReduxDefaultProviders";
import { type Store } from "redux";
import SidebarNavigation from "components/SidebarNavigation/SidebarNavigation";
import TopBar from "components/TopNavigation/TopBar";
import { getNewButton, renderBreadcrumbsIfDefined } from "./bootstrapNavigation";
import TopNavigation from "components/TopNavigation/TopNavigation";
import PreviewPanelRoot from "components/PreviewPanel/PreviewPanelRoot";
import { PreviewPanelProvider } from "components/PreviewPanel/PreviewPanelContext";
import { updateMetaThemeColor } from "components/theme/theme";
interface SystemOneAppProps {
  store: Store<any>
  fixedHeight?: boolean
}

const SystemOneApp = (props: React.PropsWithChildren<SystemOneAppProps>) => {
  
  React.useEffect(() => {
    updateMetaThemeColor();
  }, []);

  if (site.currentEnvironment === null) {
    return <ReduxDefaultProviders store={props.store}>
      <div id="pagecontainer">
        <div id="contentholder">
          {props.children}
        </div>
      </div>
    </ReduxDefaultProviders>;
  }

  return <ReduxDefaultProviders store={props.store}>
    <PreviewPanelProvider>
      <div id="pagecontainer">
        <SidebarNavigation
          navigation={site.navigation}
          currentPage={site.currentPage}
        />
        <div className="flex">
          <div className={props.fixedHeight ? "app-body-fixed-height" : undefined} style={{
            width: "100%",
            overflowX: "hidden",
          }}>
            <TopBar
              currentUser={site.currentUser}
              environmentType={site.currentEnvironment.type}
              notificationCount={site.notificationCount}
              onMenuOpen={() => { window.onSideNavOpen(); }}
            />
            {renderBreadcrumbsIfDefined()}
            <TopNavigation
              currentPage={site.currentPage}
              navigation={site.navigation}
              newButton={getNewButton()}
            />
            <div className={props.fixedHeight ? "content-holder-fixed-height" : undefined} id="contentholder">
              {props.children}
            </div>
          </div>
          <PreviewPanelRoot />
        </div>
      </div>
    </PreviewPanelProvider>
  </ReduxDefaultProviders>;
};

export default SystemOneApp;