import React from "react";

interface BreadcrumbsProps {
  breadcrumbs: Array<{
    title: string
    url: string
  }>
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  return <ul className="breadcrumbs-wrapper">
    {props.breadcrumbs.map((breadcrumb, index) => {
      const key = `${breadcrumb.title}:${breadcrumb.url}`;

      if (index === props.breadcrumbs.length - 1) {
        return <li key={key} className="breadcrumbs-item">
          <span>{breadcrumb.title}</span>
        </li>;
      }

      return <React.Fragment key={key}>
        <li className="breadcrumbs-item">
          {breadcrumb.url && <a className="breadcrumbs-link" href={breadcrumb.url}>{breadcrumb.title}</a>}
          {!breadcrumb.url && <span className="breadcrumbs-link">{breadcrumb.title}</span>}
        </li>
        <li className="breadcrumbs-item">
          <span className="breadcrumbs-separator info-text-medium">/</span>
        </li>
      </React.Fragment>;
    })}
  </ul>;
};

export default Breadcrumbs;