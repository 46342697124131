import { PhosphorFileDashed, PhosphorGearSix } from "components/Atoms/PhosphorIcon";
import { PhosphorGearButton } from "components/Atoms/PhosphorIconButton";
import React from "react";
import Popup from "reactjs-popup";
import { routes } from "utilities/routing/generatedRoutes";
import { HorizontalKeyboardNavigationGroup } from "keyboardNavigation/KeyboardNavigationPresets";


const SettingsMenu = () => {
  if (!site.permissions.canConfigureSystemSettings && !site.permissions.canConfigureTemplates) {
    return null;
  };

  return <Popup
    trigger={<PhosphorGearButton
      {...HorizontalKeyboardNavigationGroup("topBar")}
      layout="in-circle"
      iconSize="large" />}
    className="top-bar-popup"
    position="bottom right"
    closeOnDocumentClick
    onOpen={() => { }}
    onClose={() => { }}
    contentStyle={{ padding: "0px", border: "none" }}
    arrow={false}
  >
    <div aria-modal="true" className="popup-menu-wrapper">
      {site.permissions.canConfigureSystemSettings && (<a href={routes.systemSetting_Menu().absolute} className="popup-menu-item">
        <div className="menu-icon">
          <PhosphorGearSix />
        </div>
        <div className="grow1">
          {RESX.GeneralLabels.SystemSettings}
        </div>
      </a>)}
      {site.permissions.canConfigureTemplates && (<a href={routes.templateSetting_Menu().absolute} className="popup-menu-item">
        <div className="menu-icon">
          <PhosphorFileDashed />
        </div>
        <div className="grow1">
          {RESX.Template.TemplateSettings}
        </div>
      </a>)}
    </div>
  </Popup>;
};

export default SettingsMenu;
